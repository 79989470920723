import createDataContext from "./createDataContext";
import * as mainActions from "./mainActions";
import { localStorageKey, localStorageAuth } from "../config";
import produce from "immer";
import jwtDecode from "jwt-decode";

const initialState = {
  client: {},
  user: { user_id: 1 },
  jwt: { jwt: false },
  misc: { deviceInfo: {}, PWA: null, id: 0 },
  snackbar: { open: false, type: "success", duration: 5000, message: "" },
  device: { darkMode: false, username: "", token: Date.now() },
};

//device property should be preserved on login it should never be deleted.
const dataReducer = (state, action) => {
  let newData;
  let index = -1;
  let index2 = -1;

  switch (action.type) {
    case "dispatchLogs":
      newData = produce(state, (draft) => {
        draft.misc.dispatchLogs.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "unsetSync":
      newData = produce(state, (draft) => {
        draft.showSyncProgress = null;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "updateSyncSuccess":
      index = state.syncronizations.findIndex((x) => x.syncronization_id === action.payload.syncronization_id);
      newData = produce(state, (draft) => {
        draft.syncronizations[index].sync = true;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setBgSyncQueueSize":
      newData = produce(state, (draft) => {
        draft.device.bgSyncQueueSize = action.payload.bgSyncQueueSize;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "pushQueueResult":
      newData = produce(state, (draft) => {
        draft.queueLogs.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    // case "increaseBgSyncQueueSize":
    //   newData = produce(state, (draft) => {
    //     draft.bgSyncQueueSize += 1;
    //   });

    //   storeLocalData(localStorageKey, newData, true);
    //   return newData;

    case "fetchAccessCodes":
      newData = produce(state, (draft) => {
        draft.accessCodes = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCollected":
      newData = produce(state, (draft) => {
        draft.lastFetch.collected = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.collected = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchNewLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.newLoans = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.newLoans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "changeRoute":
      newData = { ...state, route: action.payload };
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "remeberUsername":
      newData = produce(state, (draft) => {
        draft.device = { ...draft.device, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setDeviceInfo":
      newData = produce(state, (draft) => {
        draft.misc.deviceInfo = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setReferral":
      newData = produce(state, (draft) => {
        draft.invite = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "increaseIncorrectAccess":
      newData = produce(state, (draft) => {
        draft.incorrectAccess = action.payload.incorrectAccess;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setSnackbar":
      newData = { ...state, snackbar: { ...state.snackbar, ...action.payload } };
      // storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setCollectedSync":
      index = state.collected.findIndex((x) => x.payment_id === action.payload.payment_id);
      // index2 = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.collected[index] = { ...state.collected[index], ...action.payload };
        // draft.loans[index2] = { ...state.loans[index2], modified_time: action.payload.modified_time };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setGastoSync":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);
      newData = produce(state, (draft) => {
        draft.gastos[index] = { ...state.gastos[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setNewLoansSync":
      index = state.newLoans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.newLoans[index] = { ...state.newLoans[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setNewDebtorSync":
      index = state.debtors.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...state.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setLoanSyncRequired":
      index = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index] = { ...state.loans[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "createRoute":
      newData = produce(state, (draft) => {
        draft.routes.push(action.payload);
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;

    case "modifyRoute":
      newData = produce(state, (draft) => {
        draft.route = { ...state.route, ...action.payload };
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setLinkedRoutes":
      newData = produce(state, (draft) => {
        draft.routes = action.payload;
      });

      // storeLocalData(localStorageKey, newData, true);
      return newData;

    case "updateRouteConfig":
      newData = produce(state, (draft) => {
        draft.routeConfig = { ...state.routeConfig, ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "cobroModifyNewLoan":
      index = state.newLoans.findIndex((loan) => loan.money_id === action.payload.money_id);
      let { amount, npayments, percentage, wPayment, adelanto, actanotarial } = action.payload;
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans[index].percentage = percentage;
        draft.newLoans[index].amount = amount;
        draft.newLoans[index].npayments = npayments;
        draft.newLoans[index].adelanto = adelanto;
        draft.newLoans[index].wPayment = wPayment;
        draft.newLoans[index].actanotarial = actanotarial;
        // draft.newLoans[index].synced = false;

        draft.misc.entregas += amount - state.newLoans[index].amount;
        draft.misc.adelanto += adelanto - state.newLoans[index].adelanto;
        draft.misc.actanotarial += actanotarial - state.newLoans[index].actanotarial;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "awaitActionCode":
      newData = produce(state, (draft) => {
        draft.awaitActionCode = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deleteNewLoan":
      index = state.newLoans.findIndex((newLoan) => newLoan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans.splice(index, 1);
        draft.misc.entregas -= state.newLoans[index].amount;
        draft.misc.adelanto -= state.newLoans[index].adelanto;
        draft.misc.actanotarial -= state.newLoans[index].actanotarial;
        draft.misc.newLoansCount--;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateLoanToggle":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index].isHidden = !state.loans[index].isHidden;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getDebtorLoans":
      const { loans, debtor_id } = action.payload;
      newData = produce(state, (draft) => {
        draft.loans = [...loans.filter((debtor) => debtor.debtor_id !== debtor_id), ...loans];
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "createGasto":
      newData = produce(state, (draft) => {
        draft.gastos.unshift(action.payload);
        draft.misc.gastos += action.payload.amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "getDebtorRenewal":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "cobroCreateGasto":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.gastos.unshift(action.payload);
        draft.misc.gastos += action.payload.amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "fetchGastos":
      newData = produce(state, (draft) => {
        draft.lastFetch.gastos = {
          timestamp: Date.now() / 1000,
          id: action.creditor_id + action.dates.start_date + action.dates.end_date,
        };
        draft.gastos = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "createLoan":
      newData = produce(state, (draft) => {
        draft.loans.unshift(action.payload);
        draft.newLoans.unshift(action.payload);
        draft.temporaryLoanData = null;
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "createPayment":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index] = { ...draft.loans[index], ...action.payload };

        draft.collected.unshift(action.payload);

        //Move loan to the last position
        draft.loans.push(draft.loans.splice(index, 1)[0]);
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "setDebtorsReordered":
      newData = produce(state, (draft) => {
        draft.misc.debtorsReorded = true;
        draft.misc.updatedDebtorsReorded = true;
        draft.debtorsReorded = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroDeleteGasto":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);

      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.gastos.splice(index, 1);
        draft.misc.gastos -= state.gastos[index].amount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deleteGasto":
      index = state.gastos.findIndex((x) => x.id === action.payload.id);
      newData = produce(state, (draft) => {
        draft.gastos.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "loanDelete":
      index = state.loans.findIndex((x) => x.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "deletePayment":
      //TODO: When deleted loan should retunrned info and update current Loan
      index = state.collected.findIndex((x) => x.payment_id === action.payload.payment_id);
      newData = produce(state, (draft) => {
        draft.collected.splice(index, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "debtorDelete":
      index = state.debtors.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      index2 = state.loans.findIndex((x) => x.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors.splice(index, 1);
        draft.loans.splice(index2, 1);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroUpdateBankAmount":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.route.bank_amount = action.payload.bankAmount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateBankAmount":
      newData = produce(state, (draft) => {
        draft.cuadre.bank_amount = action.payload.bankAmount;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateEfectivoAmount":
      newData = produce(state, (draft) => {
        draft.cuadre.actualCash = action.payload.actualCash;
        draft.cuadre.note = action.payload.note;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createNewLoan":
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.newLoans.unshift({ ...action.payload, timestamp: Date.now() });
        draft.misc.entregas += action.payload.amount;
        draft.misc.adelanto += action.payload.adelanto;
        draft.misc.actanotarial += action.payload.actanotarial;
        draft.temporaryLoanData = null;
        draft.misc.newLoansCount++;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchDebtors":
      newData = produce(state, (draft) => {
        draft.lastFetch.debtors = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.debtors = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchDebtor":
      newData = produce(state, (draft) => {
        draft.debtors.unshift(action.payload);
        draft.debtorsReorded.unshift({ debtor_id: action.payload.debtor_id, name: action.payload.name });
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "deleteSyncLoan":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      if (index === -1) return;

      newData = produce(state, (draft) => {
        draft.loans.splice(index, 1);
        //TODO: Umm? this like looks bad
        draft.misc.collected -= state.loans[index].paymentAmount;
        draft.misc.paymentMora -= state.loans[index].paymentMora;
        if (state.loans[index].paymentAmount > 0) {
          draft.misc.collectedCount--;
        }
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.loans = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.loans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCobroList":
      newData = produce(state, (draft) => {
        draft.lastFetch.cobroList = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cobroList = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCobroListChanges":
      newData = produce(state, (draft) => {
        draft.lastFetch.cobroListChanges = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cobroListChanges = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchCuadre":
      newData = produce(state, (draft) => {
        draft.lastFetch.cuadre = { timestamp: Date.now() / 1000, id: action.payload.cuadre_id };
        draft.cuadre = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroFetchLoans":
      newData = produce(state, (draft) => {
        draft.lastFetch.loans = { timestamp: Date.now() / 1000, id: action.creditor_id };
        draft.loans = action.payload.loans;
        draft.debtorsReorded = action.payload.debtorsReorded;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updatePostPone":
      newData = produce(state, (draft) => {
        draft.loans = action.payload;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "setAuthUser":
      newData = produce(state, (draft) => {
        draft.user = action.payload.user;
        draft.jwt = action.payload.jwt;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateLoansOrder":
      newData = produce(state, (draft) => {
        draft.loans = action.payload;
        draft.misc.updatedDebtorsReorded = false;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "cobroCreateDebtor":
      newData = produce(state, (draft) => {
        draft.debtors.unshift(action.payload);
        draft.newDebtors.push(action.payload);
        draft.debtorsReorded.unshift({
          debtor_id: action.payload.debtor_id,
          name: action.payload.name,
          new_debtor: true,
        });
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createDebtor":
      newData = produce(state, (draft) => {
        draft.debtors.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateDebtor":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "createEmployee":
      newData = produce(state, (draft) => {
        draft.employees.push(action.payload);
      });

      storeLocalData(localStorageKey, newData);
      return newData;

    case "cobroUpdateDebtor":
      index = state.debtors.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      index2 = state.debtorsReorded.findIndex((debtor) => debtor.debtor_id === action.payload.debtor_id);
      newData = produce(state, (draft) => {
        // draft.showSyncProgress = Date.now();
        draft.debtors[index] = { ...draft.debtors[index], ...action.payload };
        draft.debtorsReorded[index2].name = action.payload.name;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoan":
      //TODO: This will create a duplicate Loan?
      newData = produce(state, (draft) => {
        draft.loans.push(action.payload);
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "toggleDarkMode":
      newData = produce(state, (draft) => {
        draft.device.darkMode = !state.device.darkMode;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "updateDebtorLoan":
      index = state.loans.findIndex((loan) => loan.money_id === action.payload.money_id);
      newData = produce(state, (draft) => {
        draft.loans[index].name = action.payload.name;
        draft.loans[index].phone = action.payload.phone;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setPermissions":
      newData = produce(state, (draft) => {
        draft.permissions = action.payload.permissions;
        draft.roles = action.payload.roles;
      });

      storeLocalData(localStorageKey, newData);
      return newData;
    case "fetchEmployees":
      newData = produce(state, (draft) => {
        draft.employees = action.payload;
        draft.lastFetch.employees = { timestamp: Date.now() / 1000, id: action.creditor_id };
      });

      storeLocalData(localStorageKey, newData);
      return newData;

    case "fetchRouteData":
      newData = produce(state, (draft) => {
        draft.cuadre = { ...action.payload };
        draft.lastFetch.route = { timestamp: Date.now() / 1000, id: action.creditor_id };
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "restoreAppState":
      newData = produce(state, (draft) => {
        draft.user = action.payload.user;
      });

      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "restoreCobroAppState":
      newData = { ...initialState, ...action.payload };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "logout":
      //When Loging out, this dispatch will keep whatever was stored in device object.
      newData = { ...initialState, device: { ...state.device } };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "createReferralLink":
      newData = produce(state, (draft) => {
        draft.referrals.invitations.unshift(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "passwordResetLookup":
      newData = { ...state, passwordReset: { ...action.payload } };
      return newData;

    case "getSecurityQuestions":
      newData = produce(state, (draft) => {
        draft.securirityQuestions = action.payload;
        draft.lastFetch.securirityQuestions = { timestamp: Date.now() / 1000, id: action.id };
      });
      storeLocalData(localStorageKey, newData);
      return newData;

    case "setClientDetail":
      newData = { ...state, client: { ...state.client, ...action.payload } };
      storeLocalData(localStorageKey, newData, true);
      return newData;

    case "temporaryLoanData":
      newData = produce(state, (draft) => {
        draft.temporaryLoanData = action.payload;
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "setLoanIdentification":
      newData = produce(state, (draft) => {
        draft.temporaryLoanData = { ...state.temporaryLoanData, dataImage: action.payload.data };
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "phoneVerification":
      newData = { ...state, passwordNew: { ...action.payload } };
      return newData;
    case "appendDebtorImage":
      newData = produce(state, (draft) => {
        draft.debtorImages.push(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;
    case "fetchLoanSignature":
      newData = produce(state, (draft) => {
        draft.signatures.push(action.payload);
      });
      storeLocalData(localStorageKey, newData, true);
      return newData;

    default:
      if (process.env.NODE_ENV !== "production") {
        throw new Error("No Reducer found!");
      } else {
        return state;
      }
  }
};

const logout = (dispatch) => {
  return (callback) => {
    console.log("AppWorkflow - logout from dataContext - Deleted all Local Contents");
    dispatch({ type: "logout" });

    localStorage.removeItem(localStorageAuth);

    if (callback) callback();
  };
};

const setDeviceInfo = (dispatch) => {
  return async (data) => {
    try {
      dispatch({ type: "setDeviceInfo", payload: data });
    } catch (err) {
      console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
      console.log(err.message);
    }
  };
};

const restoreAppState = (dispatch) => {
  return (history) => {
    try {
      const userJwt = getLocalData(localStorageAuth);

      if (userJwt) {
        const decodedJwt = jwtDecode(userJwt);

        var current_time = Date.now() / 1000;
        if (decodedJwt.exp < current_time) {
          console.log("AppWorkflow - Auth Token is expired Logout user here.js");
          history.push("/");
          return;
        }

        dispatch({
          type: "restoreAppState",
          payload: { user: decodedJwt.data },
        });
      } else {
        console.log("AppWorkflow - Auth Token Not found Logout user here.js", localStorageAuth);
        history.push("/");
      }
      // console.log("AppWorkflow - restoreAppState from dataContext - Restored State");
    } catch (e) {}
  };
};

const storeLocalData = (key, value, allowInProduction = false) => {
  if (process.env.NODE_ENV !== "production" || allowInProduction === true) {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (err) {
      console.log(err);
    }
  }
};

const getLocalData = (key) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (err) {
    console.log("AppWorkflow - getLocalData from dataContext - catch Error");
    console.log(err);
  }
};

export const { Context, Provider } = createDataContext(
  dataReducer,
  {
    ...mainActions,
    restoreAppState,
    logout,
    setDeviceInfo,
  },
  initialState
);
