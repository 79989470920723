import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Context } from "../context/DataContext";
import { UseCustomerList, UsePaymentList } from "../server/fetcher";
import Loading from "../components/Loading";
import SearchBox from "../components/common/SearchBox";
import CustomerCard from "../components/CustomerCard";
import { searchRecords } from "../functions";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useDebounce from "../hooks/useDebounce";
import BottomNavigationPanel from "../components/BottomNavigationPanel";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const { user } = state;

  const { data: customers, isLoading } = UseCustomerList(user.user_id);
  UsePaymentList(user.user_id);

  const loader = useRef(null);
  const customerxPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (!customers) return;
      if (searchText.length < 3) {
        setFilteredCustomer(customers.slice(0, page * customerxPage));
        setLoading(false);
      } else {
        setFilteredCustomer(
          searchRecords(customers, searchText, ["search", "customer_id", "invoice_id", "name", "phone"]).slice(
            0,
            customerxPage + 5
          )
        );
      }
    },
    [customers, searchText, page],
    searchText.length < 3 ? 0 : 1000
  );

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <div className="mt-2 mb-5">
      <div style={{ minHeight: "110%" }}>
        {loading || isLoading ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar clientes..." value={searchText} />

            {filteredCustomer.map((customer) => (
              <div key={customer.customer_id} xs={12}>
                <CustomerCard history={history} customer={customer} />
              </div>
            ))}
          </Box>
        )}
      </div>

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && customers?.length > page * customerxPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>

      <BottomNavigationPanel customers={customers} />
    </div>
  );
};

export default Dashboard;
